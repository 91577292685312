import * as THREE from "../../libs/three.js/build/three.module.js";
import { Line2 } from "../../libs/three.js/lines/Line2.js";
import { LineGeometry } from "../../libs/three.js/lines/LineGeometry.js";
import { LineMaterial } from "../../libs/three.js/lines/LineMaterial.js";

// DETEKT
export class GeojsonFileLoader {
  constructor() {
    this.transform = null;
    this.usecasesData = null;
    this.getColorCallback = null;
  }

  load(geojson) {
    const features = geojson.features;
    const node = new THREE.Object3D();

    const matLines = [];
    for (const feature of features) {
      const featureProperties = feature.properties;
      const className = featureProperties.label;
      const color = this.getColorCallback(className, this.usecasesData);

      const matLine = new LineMaterial({
        color: color,
        linewidth: 3, // in pixels
        resolution: new THREE.Vector2(1000, 1000),
        dashed: false,
      });

      //const fnode = this.featureToSceneNode(feature, matLine);
      const fnode = this.featureToFilledPolygon(feature, color);
      if (fnode !== null) {
        node.add(fnode);
        matLines.push(matLine);
      }
    }

    let setResolution = (x, y) => {
      for (let matLine of matLines) {
        matLine.resolution.set(x, y);
      }
    };

    let loaded = true;
    if (matLines.length === 0) {
      loaded = false;
    }

    const result = {
      features: features,
      node: node,
      loaded: loaded,
      setResolution: setResolution,
    };

    return result;
  }

  featureToFilledPolygon(feature, color) {
    let geometry = feature.geometry;

    let transform = this.transform;
    if (transform === null) {
      transform = { forward: (v) => v };
    }

    let coordinates = [];
    let min = new THREE.Vector3(Infinity, Infinity, Infinity);
    let max = new THREE.Vector3(-Infinity, -Infinity, -Infinity);

    for (let pc of geometry.coordinates) {
      for (let i = 0; i < pc.length; i++) {
        let [long, lat, z] = pc[i];
        if (z === undefined) {
          continue;
        }
        let pos = transform.forward([long, lat, z]);

        min.x = Math.min(min.x, pos[0]);
        min.y = Math.min(min.y, pos[1]);
        min.z = Math.min(min.z, pos[2]);

        max.x = Math.max(min.x, pos[0]);
        max.y = Math.max(min.y, pos[1]);
        max.z = Math.max(min.z, pos[2]);

        coordinates.push(...pos);
        if (i > 0 && i < pc.length - 1) {
          coordinates.push(...pos);
        }
      }
      break;
    }

    if (coordinates.length === 0) {
      return null;
    }

    const vectorCoords = [];
    for (let i = 0; i < coordinates.length; i += 3) {
      // Make all points z value be the max z value found for any coordinate point so they are all at one level
      //vectorCoords.push(new THREE.Vector3(coordinates[i + 0], coordinates[i + 1], max.z/*coordinates[i + 2]*/));

      // Create a 3D point using the point coordinates
      vectorCoords.push(
        new THREE.Vector3(
          coordinates[i + 0],
          coordinates[i + 1],
          coordinates[i + 2]
        )
      );
    }

    var shape = new THREE.Shape(vectorCoords);
    var shapeGeometry = new THREE.ShapeGeometry(shape);
    let mesh = new THREE.Mesh(
      shapeGeometry,
      new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide })
    );
    mesh.geometry.vertices = vectorCoords;
    mesh.scale.set(1, 1, 1);

    return mesh;
  }

  featureToSceneNode(feature, matLine) {
    let geometry = feature.geometry;

    let color = new THREE.Color(1, 1, 1);

    let transform = this.transform;
    if (transform === null) {
      transform = { forward: (v) => v };
    }

    if (geometry.type === "Point") {
      let sg = new THREE.SphereGeometry(1, 18, 18);
      let sm = new THREE.MeshNormalMaterial();
      let s = new THREE.Mesh(sg, sm);

      let [long, lat, z] = geometry.coordinates;
      let pos = transform.forward([long, lat, z]);

      s.position.set(...pos);

      s.scale.set(10, 10, 10);

      return s;
    } else if (geometry.type === "LineString") {
      let coordinates = [];

      let min = new THREE.Vector3(Infinity, Infinity, Infinity);
      for (let i = 0; i < geometry.coordinates.length; i++) {
        let [long, lat, z] = geometry.coordinates[i];
        let pos = transform.forward([long, lat, z]);

        min.x = Math.min(min.x, pos[0]);
        min.y = Math.min(min.y, pos[1]);
        min.z = Math.min(min.z, pos[2]);

        coordinates.push(...pos);
        if (i > 0 && i < geometry.coordinates.length - 1) {
          coordinates.push(...pos);
        }
      }

      for (let i = 0; i < coordinates.length; i += 3) {
        coordinates[i + 0] -= min.x;
        coordinates[i + 1] -= min.y;
        coordinates[i + 2] -= min.z;
      }

      const lineGeometry = new LineGeometry();
      lineGeometry.setPositions(coordinates);

      const line = new Line2(lineGeometry, matLine);
      line.computeLineDistances();
      line.scale.set(1, 1, 1);
      line.position.copy(min);

      return line;
    } else if (geometry.type === "Polygon") {
      for (let pc of geometry.coordinates) {
        let coordinates = [];

        let min = new THREE.Vector3(Infinity, Infinity, Infinity);
        for (let i = 0; i < pc.length; i++) {
          let [long, lat, z] = pc[i];
          if (z === undefined) {
            continue;
          }
          let pos = transform.forward([long, lat, z]);

          min.x = Math.min(min.x, pos[0]);
          min.y = Math.min(min.y, pos[1]);
          min.z = Math.min(min.z, pos[2]);

          coordinates.push(...pos);
          if (i > 0 && i < pc.length - 1) {
            coordinates.push(...pos);
          }
        }

        for (let i = 0; i < coordinates.length; i += 3) {
          coordinates[i + 0] -= min.x;
          coordinates[i + 1] -= min.y;
          coordinates[i + 2] -= min.z;
        }

        if (coordinates.length === 0) {
          return null;
        }

        const lineGeometry = new LineGeometry();
        lineGeometry.setPositions(coordinates);

        const line = new Line2(lineGeometry, matLine);
        line.computeLineDistances();
        line.scale.set(1, 1, 1);
        line.position.copy(min);

        return line;
      }
    } else {
      console.log("unhandled feature: ", feature);
    }
  }
}
